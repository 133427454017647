import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Section from "../components/section"
import SEO from "../components/seo"

import ig from "../images/instagram_logo.png"
import gh from "../images/github_logo.png"
import email from "../images/email_icon.png"
import linkedin from "../images/linkedin.png"

const ExpList = styled.p`
  margin-bottom: 1em;
`

const ListDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;

  a {
    color: black;
    display: flex;
  }
`

const ConnectLogo = styled.img`
  margin-right: 0.5em;
  margin-bottom: 0;
`

const ConnectHeader = styled.h2`
  margin-top: 2em;
`

const AboutPage = () => (
  <Layout>
    <SEO title="About" description="About iangohy" />
    <Section backgroundColor="#abf0e9" title="About" height="5%">
      <h2>Ian Goh</h2>
      <p>Undergraduate student from Singapore.</p>
      <ConnectHeader>Connect with me!</ConnectHeader>
      <ListDiv>
        <a href="https://github.com/iangohy">
          <ConnectLogo src={gh} alt="GitHub logo" width="30px" height="30px" />
          GitHub
        </a>
      </ListDiv>
      <ListDiv>
        <a href="https://www.linkedin.com/in/iangohy/">
          <ConnectLogo
            src={linkedin}
            alt="Linkedin Logo"
            width="35px"
            height="30px"
          />
          Linkedin
        </a>
      </ListDiv>
      <ListDiv>
        <a href="https://www.instagram.com/iangohy/">
          <ConnectLogo
            src={ig}
            alt="Instagram logo"
            width="30px"
            height="30px"
          />
          Instagram
        </a>
      </ListDiv>
      <ListDiv>
        <a href="mailto:ian@iangohy.com">
          <ConnectLogo
            src={email}
            alt="Email icon"
            width="30px"
            height="30px"
          />
          ian@iangohy.com
        </a>
      </ListDiv>
    </Section>

    {/* <Section backgroundColor="#A1F7DC" height="auto">
      <h2>Experimented with</h2>
      <ExpList>Python</ExpList>
      <ExpList>Javascript</ExpList>
      <ExpList>C++</ExpList>
      <ExpList>
        React - <a href="https://coin.iangohy.com">Coin Toss</a>,{" "}
        <a href="https://ttt.iangohy.com">Tic-Tac-Toe</a>,{" "}
        <a href="https://slot.iangohy.com">NTUC Slots</a>
      </ExpList>
      <ExpList>React Native</ExpList>
      <ExpList>
        Gatsby - <a href="https://iangohy.com">iangohy.com</a>
      </ExpList>
      <ExpList>
        Google Cloud Platform (GCE, App Engine, Cloud Storage, Cloud Functions)
      </ExpList>
      <ExpList>Docker</ExpList>
      <ExpList>
        Zilliqa Blockchain -{" "}
        <a href="https://github.com/iangohy/zilliqa-review-federation-poc">
          Review Federation
        </a>
      </ExpList>
    </Section> */}

    {/* <Section backgroundColor="#82D3E6" height="auto">
    </Section> */}
  </Layout>
)

export default AboutPage
